<template>
  <div>
    <v-btn class="mt-6" color="primary" :to="{ name: 'new_payment_method' }"> New </v-btn>
    <v-card>
      <v-card-title>
        {{ translate("Origins") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="items"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-dialog v-model="dialog" :retain-focus="false" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="color: var(--v-warning-base)"
                  @click.stop="
                    editing_item = JSON.parse(JSON.stringify(item));
                    item.dialog = true;
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="editClicked(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  {{ translate("Edit Payment Method") }}
                </v-card-title>
                <v-card-text>
                  <v-col>
                    <v-text-field
                      v-model="edit_supplier_name"
                      outlined
                      dense
                      class="shrink"
                      :label="translate('Text')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="edit_devpos_id"
                      outlined
                      dense
                      class="shrink"
                      :label="translate('Devpos ID')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="handleEdit(item.id)">
                    {{ translate("SAVE") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog :retain-focus="false" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click.stop="
                    editing_item = JSON.parse(JSON.stringify(item));
                    item.delete_dialog = true;
                  "
                  small
                  style="color: var(--v-error-base)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h5">Deleting {{ item.name }}</v-card-title>
                <v-card-text>
                  {{ translate("Are you sure want to delete this item") }}?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                    translate("Cancel")
                  }}</v-btn>
                  <v-btn color="primary" @click="handleDelete(item.id)">{{
                    translate("OK")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { mdiClipboardPlusOutline, mdiDotsVertical } from "@mdi/js";
import {
  deleteSupplier,
  editSupplier,
  getOrigins,
  getSuppliers,
  editOrigin,
  getPaymentMethods,
  deletePaymentMethod,
  editPaymentMethod,
} from "@/ApiManager";

export default {
  setup() {
    const edit_supplier_name = ref("");
    const edit_supplier_email = ref("");
    const edit_supplier_phone = ref("");
    const edit_devpos_id = ref("");

    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
      edit_supplier_name,
      edit_supplier_email,
      edit_supplier_phone,
      edit_devpos_id,
    };
  },
  data() {
    return {
      search: "",
      dialog: false,
      delete_dialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: this.translate("Text"), value: "text" },
        { text: this.translate("Devpos ID"), value: "devpos_id" },

        { text: this.translate("Actions"), value: "actions" },
      ],
      items: [],
    };
  },
  created() {
    this.$store.state.loading = true;
    getPaymentMethods().then((response) => {
      this.items = response;
      this.$store.state.loading = false;
    });
  },
  methods: {
    editClicked(item) {
      this.edit_supplier_name = item.text;
      this.edit_devpos_id = item.devpos_id;
    },
    handleDelete(id) {
      deletePaymentMethod(id).then(() => {
        getPaymentMethods().then((response) => (this.items = response));
      });
      this.delete_dialog = false;
    },
    handleEdit(id) {
      this.$store.state.loading = true;
      this.dialog = false;
      editPaymentMethod(id, this.edit_supplier_name, this.edit_devpos_id).then(() => {
        getPaymentMethods().then((response) => {
          this.items = response;
          this.$store.state.loading = false;
        });
      });
      this.edit_supplier_name = "";
      this.edit_supplier_email = "";
      this.edit_supplier_phone = "";
    },
  },
};
</script>
